import React from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';

const ErrorPage = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="text-center p-8 bg-white rounded-lg shadow-md max-w-md w-full">
        <h1 className="text-6xl font-bold text-[#153247] mb-4">Oops!</h1>
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">
          Something went wrong
        </h2>
        
        <div className="text-gray-500 mb-6">
          {error?.statusText || error?.message || 
            "Sorry, an unexpected error has occurred."}
        </div>

        <div className="space-y-4">
          <button
            onClick={() => navigate(-1)}
            className="bg-gray-200 text-[#153247] px-6 py-2 rounded-full hover:bg-gray-300 mr-4"
          >
            Go Back
          </button>
          
          <button
            onClick={() => navigate('/')}
            className="bg-[#153247] text-white px-6 py-2 rounded-full hover:bg-[#1f4a6b]"
          >
            Return Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage; 